import React, { useState } from 'react'
import Sidebar from '../../Components/Sidebar/Sidebar'

import "./card.scss"
import { Grid, TextField } from '@mui/material'

const Card = () => {
    const [token, setToken] = useState("")
    const [cardId, setCardId] = useState("")

    const test = () => {
        console.log(process.env.REACT_APP_SUCCESSCARD)
    }

    return (
        <>
            <Sidebar/>
            <section className="containerTrx">
                <Grid container direction={'row'} justifyContent={"space-around"} mt={4} mb={4}>
                    <Grid item xs={12} sm={5} mr={1} mb={4}>
                        <TextField id='token' fullWidth={true} label={"Token"} multiline maxRows={25} onChange={(e) => setToken(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} sm={5} mr={1} mb={4}>
                        <TextField id='cardid' fullWidth={true}  label="Card ID" onChange={(e) => setCardId(e.target.value)}/>
                    </Grid>

                    <Grid item xs={12} sm={5} mr={1}>
                        <iframe title='test' src={`https://secure-data-web-stage.pomelo.la/v1/${cardId}?auth=${token}&field_list=expiration,pan,code,pin,name&layout=card&locale=es&styles=https://neocities.org/site_files/text_editor/css/card.css`} allow='clipboard-write' width="450" height="250"></iframe>
                    </Grid>
                    <Grid item xs={12} sm={5} mr={1}>
                        <iframe title='test' src={`https://secure-data-web-stage.pomelo.la/v1/activate-card?auth=${token}&locale=es&success_link=${process.env.REACT_APP_SUCCESSCARD}/${cardId}`} allow='clipboard-write' width="450" height="250"></iframe>
                    </Grid>
                </Grid>

            </section>
        </>
    )
}

export default Card