import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'

import Sidebar from "../../Components/Sidebar/Sidebar"

const Home = () => {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        document.body.classList.remove('bodyLogin');
        getUser()
    })

    const getUser =  async () => {
        await axios.get(process.env.REACT_APP_URL_API + 'user', {
            withCredentials: true
        }).then((response) => {
            // const data = response.data;
            // console.log(response.data)
        })
        .catch(function (error) {
            if(error.response.status === 401){
                setRedirect(true)
            }
        });
    }

    if(redirect){
        return <Navigate to="/login"/>
    }

    return (
        <Sidebar/>
    )
}

export default Home