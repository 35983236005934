import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import Modal from '../../Components/Transaction/Modal/Modal.jsx';

import Sidebar from '../../Components/Sidebar/Sidebar'

import "./transaction.scss"
import ExportButton from '../../Components/Transaction/ExportButton/ExportButton.jsx'

const Transaction = () => {
    const [open, setOpen] = useState(false);
    const [identificator, setIdentificator] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [searched, setSearched] = useState(null)
    const [dataExcel, setDataExcel] = useState([])
    const [dateFilter, setDateFilter] = useState({
        dateFrom: null,
        dateTo: null,
        selected: -1,
        currency: "ALL",
        status: "ALL",
        brand: "ALL",
        product: "ALL"
    });

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    useEffect(() => {
        getUser()
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.status === "ALL" && dateFilter.currency === "ALL" && dateFilter.brand === "ALL" && dateFilter.product === "ALL") {
                getTransactions()
                getTransactionsExcel()
            } else {
                searchTransactions()
                searchTransactionsExcel()
            }
        }, 500)

        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.page, pageState.pageSize])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.status === "ALL" && dateFilter.currency === "ALL" && dateFilter.brand === "ALL" && dateFilter.product === "ALL") {
                setPageState(old => ({ ...old, page: 1 }))
                getTransactions()
                getTransactionsExcel()
            } else {
                searchTransactions()
                searchTransactionsExcel()
            }
        }, 500)

        return () => clearTimeout(timer)
    }, [searched, dateFilter.dateFrom, dateFilter.dateTo, dateFilter.status, dateFilter.currency, dateFilter.brand, dateFilter.product])

    const columns = [
        {
            field: 'id', width: 60, headerName: '#', align: 'right'
        },
        {
            field: 'status', width: 90, headerName: "Status"
        },
        {
            field: 'result', width: 180, headerName: "Result"
        },
        {
            field: 'currency', width: 70, headerName: "Currency"
        },
        {
            field: 'amount', width: 100, headerName: "Amount", align: 'right'
        },
        {
            field: 'refund', width: 80, headerName: "Refunds", align: 'right'
        },
        {
            field: 'updated_at', width: 200, headerName: "Last Update", valueGetter: (paramas) => {
                return new Date(paramas.value).toLocaleString('en-GB');
            }
        },
        {
            field: 'brand', width: 80, headerName: "Brand"
        },
        {
            field: 'product', width: 80, headerName: "Product"
        },
        {
            field: 'bank', width: 150, headerName: "Bank"
        },
        {
            field: 'bin', width: 80, headerName: "Bin", align: 'right'
        },
        {
            field: 'card', width: 80, headerName: "Card"
        },
        {
            field: 'auth', width: 80, headerName: "Auth"
        },
        {
            field: 'identificator', width: 310, headerName: "Identifier"
        },
        {
            field: 'external_transaction_id', width: 150, headerName: "Trx.ID"
        },
        {
            field: 'external_customer_id', width: 150, headerName: "Cust.ID"
        },
        {
            field: 'merchant_name', width:150, headerName: "Name"
        },
        {
            field: 'namePR', width: 95, headerName: "Gateway"
        },
        {
            field: 'gw_trxId', width: 150, headerName: "Operation", align: 'right'
        },
        {
            field: 'external_customer_email', width: 150, headerName: "Email"
        },
        {
            field: 'name', width:120, headerName: "Client"
        },
        {
            field: 'reason', width:250, headerName: "Reject reason"
        },
        {
            field: 'external_id', width:100, headerName: "IMEI"
        }
    ]

    const getUser = async () => {
        await axios.get(process.env.REACT_APP_URL_API + 'user', {
            withCredentials: true
        }).then((response) => {
            // const data = response.data;
            // console.log(response.data)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setRedirect(true)
                }
            });
    }

    if (redirect) {
        return <Navigate to="/login" />
    }

    const getTransactions = async () => {
        setPageState(old => ({ ...old, isLoading: true }))

        await axios.get(process.env.REACT_APP_URL_API + `front/transactions?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        })
            .then(function (response) {
                setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))
            })
            .catch(function (error) {

                if (error.response.status === 401) {
                    toast.error('Credenciales Invalidas', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const searchTransactions = async () => {
        setPageState(old => ({ ...old, isLoading: true }))

        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.status + "," + dateFilter.currency + "," + dateFilter.brand + "," + dateFilter.product

        // console.log(searchedVal);

        await axios.get(process.env.REACT_APP_URL_API + `front/transactions/search/${searchedVal}?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        }).then((response) => {
            // console.log(response);
            setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const getTransactionsExcel = async () => {
        await axios.get(process.env.REACT_APP_URL_API + `front/transactionsExcel`, {
            withCredentials: true
        })
        .then(function (response) {
            let listTrx = response.data.map((d) => {
                return {
                    ID: d.id,
                    Status: d.status,
                    Result: d.result,
                    Reject_Reason: d.reason,
                    Currency: d.currency,
                    Amount: d.amount,
                    Refunds: d.refund,
                    LastUpdate: new Date(d.updated_at).toLocaleString('en-GB'),
                    Brand: d.brand,
                    Product: d.product,
                    Bank: d.bank,
                    Bin: d.bin,
                    Card: d.card,
                    Auth: d.auth,
                    Identifier: d.identificator,
                    TrxId: d.external_transaction_id,
                    CustID: d.external_customer_id,
                    IMEI: d.external_id,
                    Name: d.merchant_name,
                    Gateway: d.namePR,
                    Operation: d.gw_trxId,
                    Email: d.external_customer_email,
                    Client: d.name,
                }

            })

            setDataExcel(listTrx)
        })
        .catch(function (error) {

            if (error.response.status === 401) {
                toast.error('Credenciales Invalidas', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    }

    const searchTransactionsExcel = async () => {
        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.status + "," + dateFilter.currency + "," + dateFilter.brand + "," + dateFilter.product

        await axios.get(process.env.REACT_APP_URL_API + `front/transactionsExcel/search/${searchedVal}`, {
            withCredentials: true
        }).then((response) => {
            let listTrx = response.data.map((d) => {
                return {
                    ID: d.id,
                    Status: d.status,
                    Result: d.result,
                    Reject_Reason: d.reason,
                    Currency: d.currency,
                    Amount: d.amount,
                    Refunds: d.refund,
                    LastUpdate: new Date(d.updated_at).toLocaleString('en-GB'),
                    Brand: d.brand,
                    Product: d.product,
                    Bank: d.bank,
                    Bin: d.bin,
                    Card: d.card,
                    Auth: d.auth,
                    Identifier: d.identificator,
                    TrxId: d.external_transaction_id,
                    CustID: d.external_customer_id,
                    IMEI: d.external_id,
                    Name: d.merchant_name,
                    Gateway: d.namePR,
                    Operation: d.gw_trxId,
                    Email: d.external_customer_email,
                    Client: d.name
                }
            })

            setDataExcel(listTrx)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Sidebar />
            <ToastContainer />
            <section className="containerTrx">
                <Box sx={{
                    height: 2650, width: '98%', margin: '20px', '& .refundS': {
                        background: '#ffe599'
                    }
                }}>
                    <Typography variant='h5' component='h5' sx={{ textAlign: 'left', mb: 3, fontWeight: 'bold', fontSize: 18 }}>
                        {/* { title } */}Cards
                    </Typography>
                    {/* <Searchbar getTrx={getTransactions} setPage={setPageState}/> */}

                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <DemoContainer components={['DatePicker']}> */}
                                <DatePicker disableFuture label="From" value={dateFilter.dateFrom} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateFrom: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                                {/* </DemoContainer> */}
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <DemoContainer components={['DatePicker']}> */}
                                <DatePicker disableFuture label="To" value={dateFilter.dateTo} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateTo: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                                {/* </DemoContainer> */}
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Status" select value={dateFilter.status} onChange={(newValue) => { setDateFilter({ ...dateFilter, status: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="Canceled">CANCELED</MenuItem>
                                <MenuItem value="Completed">COMPLETED</MenuItem>
                                <MenuItem value="Pending">PENDING</MenuItem>
                                <MenuItem value="Rejected">REJECTED</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Currency" select value={dateFilter.currency} onChange={(newValue) => { setDateFilter({ ...dateFilter, currency: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="ARS">ARS</MenuItem>
                                <MenuItem value="USD">USD</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Brand" select value={dateFilter.brand} onChange={(newValue) => { setDateFilter({ ...dateFilter, brand: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="AMERICAN EXPRESS">AMERICAN EXPRESS</MenuItem>
                                <MenuItem value="CHINA UNIONPAY">CHINA UNIONPAY</MenuItem>
                                <MenuItem value="DINERS CLUB">DINERS CLUB</MenuItem>
                                <MenuItem value="DISCOVER">DISCOVER</MenuItem>
                                <MenuItem value="JCB">JCB</MenuItem>
                                <MenuItem value="MASTERCARD">MASTERCARD</MenuItem>
                                <MenuItem value="VISA">VISA</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Product" select value={dateFilter.product} onChange={(newValue) => { setDateFilter({ ...dateFilter, product: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="CREDIT">CREDIT</MenuItem>
                                <MenuItem value="DEBIT">DEBIT</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container mb={2}>
                        <Grid item xs={5} md={11} xl={10}>
                            <div className='input-wrapper'>
                                <SearchIcon id="search-icon"></SearchIcon>
                                <input placeholder='Search...' value={searched} onChange={(val) => setSearched(val.target.value)} />
                            </div>
                        </Grid>
                        <Grid item xs={7} md={1} xl={2}>
                            <Grid container justifyContent="flex-end">
                                <ExportButton data={dataExcel}></ExportButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <DataGrid
                        rowHeight={50}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        // pageSizeOptions={[5, 10, 25]}
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        paginationMode='server'
                        onPageChange={(newPage) => {
                            // console.log("asdsa");
                            setPageState(old => ({ ...old, page: newPage + 1 }))
                        }}
                        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                        columns={columns}
                        onRowClick={(params, event) => {
                            setIdentificator(params.row.identificator)
                            setOpen(true);

                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'refund' && params.value !== null) {
                                return 'refundS'
                            }
                        }}
                        // filterModel={
                        //     {
                        //         items: [{
                        //             columnField: 'result', operatorValue: 'contains', value: 'close'
                        //         }]
                        //     }
                        // }
                        disableColumnMenu
                        sx={{ mt: 2 }}
                    >

                    </DataGrid>
                </Box>
            </section>

            {open ? <Modal openModal={open} idenTrx={identificator} onCloseModal={handleClose} /> : null}
        </div>
    )
}

export default Transaction