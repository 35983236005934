import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import Modal from '../../Components/ServicesPayments/Modal/Modal.jsx';

import Sidebar from '../../Components/Sidebar/Sidebar.jsx'

import "./servicespayments.scss"
import ExportButton from '../../Components/ServicesPayments/ExportButton/ExportButton.jsx'
import { Email } from '@mui/icons-material'

const ServicesPayments = () => {
    const [open, setOpen] = useState(false);
    const [identificator, setIdentificator] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [searched, setSearched] = useState(null)
    const [dataExcel, setDataExcel] = useState([])
    const [dateFilter, setDateFilter] = useState({
        dateFrom: null,
        dateTo: null,
        selected: -1,

        app: "ALL",
        service: "ALL",
        status: "ALL"
    });

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.status === "ALL" && dateFilter.app === "ALL" && dateFilter.service === "ALL" ) {
                getServices()
                getServicesExcel()
            } else {
                searchServices()
                searchServicesExcel()
            }
        }, 1300)

        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.page, pageState.pageSize])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.status === "ALL" && dateFilter.app === "ALL" && dateFilter.service === "ALL"  ) {
                setPageState(old => ({ ...old, page: 1 }))
                getServices()
                getServicesExcel()
            } else {
                searchServices()
                searchServicesExcel()
            }
        }, 1300)

        return () => clearTimeout(timer)
    }, [searched, dateFilter.dateFrom, dateFilter.dateTo, dateFilter.status, dateFilter.app, dateFilter.service])

    const columns = [
        {
            field: 'App', width: 150, headerName: "App"
        },
        {
            field: 'EMail', width: 240, headerName: "EMail"
        },
        {
            field: 'Service', width: 130, headerName: "Service"
        },
        {
            field: 'Amount', width: 140, headerName: "Processor"
        },
        {
            field: 'Status', width: 140, headerName: "Status"
        },
        {
            field: 'BarCode', width: 400, headerName: "BarCode"
        },
        {
            field: 'Invoice', width: 140, headerName: "Invoice"
        },

        {
            field: 'PmntOrder', width: 150, headerName: "PmntOrder"
        },

        {
            field: 'GwTrxId', width: 150, headerName: "GwTrxId"
        },

        {
            field: 'GwServId', width: 150, headerName: "GwServId"
        },

        {
            field: 'GwTag', width: 150, headerName: "GwTag"
        },

        {
            field: 'WithInvoice', width: 150, headerName: "WithInvoice"
        },

        {
            field: 'LastUpd', width: 250, headerName: "LastUpd"
        }
    ]

    const getUser = async () => {
        await axios.get(process.env.REACT_APP_URL_API + 'user', {
            withCredentials: true
        }).then((response) => {
            // const data = response.data;
            // console.log(response.data)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setRedirect(true)
                }
            });
    }

    if (redirect) {
        return <Navigate to="/login" />
    }

    const getServices = async () => {

        setPageState(old => ({ ...old, isLoading: true }))

     
        await axios.get(process.env.REACT_APP_URL_API + `front/servicespayments?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        })
            .then(function (response) {

                setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))

            })
            .catch(function (error) {

    
                if (error.response.status === 401) {
                    toast.error('Credenciales Invalidas', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const searchServices = async () => {
        setPageState(old => ({ ...old, isLoading: true }))

        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.status + "," + dateFilter.app+ "," + dateFilter.service

        await axios.get(process.env.REACT_APP_URL_API + `front/servicespayments/search/${searchedVal}?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        }).then((response) => {
            // console.log(response);
            setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const getServicesExcel = async () => {
        await axios.get(process.env.REACT_APP_URL_API + `front/servicespaymentsExcel`, {
            withCredentials: true
        })
        .then(function (response) {
            let listTrx = response.data.map((d) => {
                return {
                   
                    Id: d.Id,
                    App: d.App,
                    Email: d.Email,
                    Service: d.Service,
                    Amount: d.Amount,
                    Status: d.Status,
                    Barcode: d.Barcode,
                    Invoice: d.Invoice,
                    PmntOrder: d.PmntOrder,
                    GwTrxId: d.GwTrxId,
                    GwServId: d.GwServId,
                    GwTag: d.GwTag,
                    WithInvoice: d.WithInvoice,
                    LastUpd: d.LastUpd
                }

            })

            setDataExcel(listTrx)
        })
        .catch(function (error) {

            if (error.response.status === 401) {
                toast.error('Credenciales Invalidas', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    }

    const searchServicesExcel = async () => {
        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.status + "," + dateFilter.app + "," + dateFilter.service 

        await axios.get(process.env.REACT_APP_URL_API + `front/servicespaymentsExcel/search/${searchedVal}`, {
            withCredentials: true
        }).then((response) => {
            let listTrx = response.data.map((d) => {
                return {
                    Id: d.Id,
                    App: d.App,
                    Email: d.Email,
                    Service: d.Service,
                    Amount: d.Amount,
                    Status: d.Status,
                    Barcode: d.Barcode,
                    Invoice: d.Invoice,
                    PmntOrder: d.PmntOrder,
                    GwTrxId: d.GwTrxId,
                    GwServId: d.GwServId,
                    GwTag: d.GwTag,
                    WithInvoice: d.WithInvoice,
                    LastUpd: d.LastUpd
                }
            })

            setDataExcel(listTrx)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Sidebar />
            <ToastContainer />
            <section className="containerTrx">
                <Box sx={{
                    height: 2650, width: '98%', margin: '20px', '& .refundS': {
                        background: '#ffe599'
                    }
                }}>
                    <Typography variant='h5' component='h5' sx={{ textAlign: 'left', mb: 3, fontWeight: 'bold', fontSize: 18 }}>
                        {/* { title } */}Services Payments
                    </Typography>
                    {/* <Searchbar getTrx={getServices} setPage={setPageState}/> */}

                    <Grid container justifyContent="space-between">

{/*}

                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                               
                                <DatePicker disableFuture label="From" value={dateFilter.dateFrom} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateFrom: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                             
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              
                                <DatePicker disableFuture label="To" value={dateFilter.dateTo} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateTo: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                           
                            </LocalizationProvider>
                        </Grid>

*/}

                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField 
                                label="Service" 
                                select 
                                value={dateFilter.service} 
                                onChange={(newValue) => { setDateFilter({ ...dateFilter, service: newValue.target.value }) }} 
                                fullWidth 
                                color='info' 
                                size='small'
                            >
                             <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="A.M.A.U.P.C.N">A.M.A.U.P.C.N</MenuItem>
                                <MenuItem value="CLARO">CLARO</MenuItem>
                                <MenuItem value="La Caja de Seguros (La Caja)">La Caja de Seguros (La Caja)</MenuItem>
                                <MenuItem value="">--</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="App" select value={dateFilter.app} onChange={(newValue) => { setDateFilter({ ...dateFilter, app: newValue.target.value }) }} fullWidth color='info' size='small'>
                                 <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="QUIKIPAY">QUIKIPAY</MenuItem>
                                <MenuItem value="">--</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Status" select value={dateFilter.status} onChange={(newValue) => { setDateFilter({ ...dateFilter, status: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="PENDING">PENDING</MenuItem>
                            </TextField>
                        </Grid>

                    </Grid>

                    <Grid container mb={2}>
                        <Grid item xs={5} md={11} xl={10}>
                            <div className='input-wrapper'>
                                <SearchIcon id="search-icon"></SearchIcon>
                                <input placeholder='Search...' value={searched} onChange={(val) => setSearched(val.target.value)} />
                            </div>
                        </Grid>
                        <Grid item xs={7} md={1} xl={2}>
                            <Grid container justifyContent="flex-end">
                                <ExportButton data={dataExcel}></ExportButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <DataGrid
                        rowHeight={50}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        // pageSizeOptions={[5, 10, 25]}
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        paginationMode='server'
                        onPageChange={(newPage) => {
                         
                            setPageState(old => ({ ...old, page: newPage + 1 }))
                        }}
                        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                        columns={columns}
                        getRowId={(row) => row.Id}
                        onRowClick={(params, event) => {
                            setIdentificator(params.row.identificator)
                            setOpen(true);

                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'refund' && params.value !== null) {
                                return 'refundS'
                            }
                        }}
                        // filterModel={
                        //     {
                        //         items: [{
                        //             columnField: 'result', operatorValue: 'contains', value: 'close'
                        //         }]
                        //     }
                        // }
                        disableColumnMenu
                        sx={{ mt: 2 }}
                    >

                    </DataGrid>
                </Box>
            </section>

            {open ? <Modal openModal={open} idenTrx={identificator} onCloseModal={handleClose} /> : null}
        </div>
    )
}

export default ServicesPayments