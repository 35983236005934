import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import Modal from '../../Components/CvuTransaction/Modal/Modal.jsx';

import Sidebar from '../../Components/Sidebar/Sidebar.jsx'

import "./cvutransaction.scss"
import ExportButton from '../../Components/CvuTransaction/ExportButton/ExportButton.jsx'

const CvuTransaction = () => {
    const [open, setOpen] = useState(false);
    const [identificator, setIdentificator] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [searched, setSearched] = useState(null)
    const [dataExcel, setDataExcel] = useState([])
    const [dateFilter, setDateFilter] = useState({
        dateFrom: null,
        dateTo: null,
        selected: -1,
        concept: "ALL",
        status: "ALL",
        type: "ALL"
    });

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    useEffect(() => {
        getUser()
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.status === "ALL" && dateFilter.concept === "ALL" && dateFilter.type === "ALL") {
                getCvuTransaction()
                getCvuTransactionExcel()
            } else {
                searchCvuTransaction()
                searchCvuTransactionExcel()
            }
        }, 500)

        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.page, pageState.pageSize])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.status === "ALL" && dateFilter.concept === "ALL" && dateFilter.type === "ALL") {
                setPageState(old => ({ ...old, page: 1 }))
                getCvuTransaction()
                getCvuTransactionExcel()
            } else {
                searchCvuTransaction()
                searchCvuTransactionExcel()
            }
        }, 500)

        return () => clearTimeout(timer)
    }, [searched, dateFilter.dateFrom, dateFilter.dateTo, dateFilter.status, dateFilter.concept, dateFilter.type])

    const columns = [
  
        {
            field: 'CVU', width: 240, headerName: "Cvu"
        },
        {
            field: 'Alias', width: 190, headerName: "Alias"
        },
        {
            field: 'Type', width: 90, headerName: "Type"
        },
        {
            field: 'Amount', width: 120, headerName: "Amount"
        },
        {
            field: 'OrderId', width: 140, headerName: "OrderId"
        },
        {
            field: 'Status', width: 140, headerName: "Status"
        },
        {
            field: 'Concept', width: 100, headerName: "Concept"
        },
        {
            field: 'Description', width: 250, headerName: "Description"
        },
        {
            field: 'Reason', width: 200, headerName: "Reason"
        },
        {
            field: 'Identificator', width: 330, headerName: "Identificator"
        },
        {
            field: 'CreatedAt', width: 200, headerName: "Created At", valueGetter: (paramas) => {
                return new Date(paramas.value).toLocaleString('en-GB');
            }
        }
    ]

    const getUser = async () => {
        await axios.get(process.env.REACT_APP_URL_API + 'user', {
            withCredentials: true
        }).then((response) => {
            // const data = response.data;
            // console.log(response.data)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setRedirect(true)
                }
            });
    }

    if (redirect) {
        return <Navigate to="/login" />
    }

    const getCvuTransaction = async () => {

        setPageState(old => ({ ...old, isLoading: true }))

        await axios.get(process.env.REACT_APP_URL_API + `front/cvutransactions?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        })
            .then(function (response) {

                setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))

            })
            .catch(function (error) {

                if (error.response.status === 401) {
                    toast.error('Credenciales Invalidas', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const searchCvuTransaction = async () => {
        setPageState(old => ({ ...old, isLoading: true }))

        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.status + "," + dateFilter.concept+ "," +  dateFilter.type

        await axios.get(process.env.REACT_APP_URL_API + `front/cvutransactions/search/${searchedVal}?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        }).then((response) => {
            // console.log(response);
           
            setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const getCvuTransactionExcel = async () => {
        await axios.get(process.env.REACT_APP_URL_API + `front/cvutransactionsExcel`, {
            withCredentials: true
        })
        .then(function (response) {
            let listTrx = response.data.map((d) => {
                return {
                   
                    Cvu: d.CVU,
                    Alias: d.Alias,
                    CreatedAt: new Date(d.CreatedAt).toLocaleString('en-GB'),
                    OrderId: d.OrderId,
                    Amount: d.Amount,
                    Status: d.Status,
                    Currency: d.Currency,
                    Amount: d.Amount,
                    Processor: d.Processor,
                    Request: d.Request,
                    Response: d.Response,
                    Identifier: d.Identifier,
                    Type: d.Type,
                    Id: d.id
                }

            })

            setDataExcel(listTrx)
        })
        .catch(function (error) {

            if (error.response.status === 401) {
                toast.error('Credenciales Invalidas', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    }

    const searchCvuTransactionExcel = async () => {
        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.status + "," + dateFilter.concept + "," + dateFilter.type

        await axios.get(process.env.REACT_APP_URL_API + `front/cvutransactionsExcel/search/${searchedVal}`, {
            withCredentials: true
        }).then((response) => {
            let listTrx = response.data.map((d) => {
                return {
                    Cvu: d.CVU,
                    Alias: d.Alias,
                    CreatedAt: new Date(d.CreatedAt).toLocaleString('en-GB'),
                    OrderId: d.OrderId,
                    Amount: d.Amount,
                    Status: d.Status,
                    Currency: d.Currency,
                    Amount: d.Amount,
                    Processor: d.Processor,
                    Request: d.Request,
                    Response: d.Response,
                    Identifier: d.Identifier,
                    Type: d.Type,
                    Id: d.id
                }
            })

            setDataExcel(listTrx)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Sidebar />
            <ToastContainer />
            <section className="containerTrx">
                <Box sx={{
                    height: 2650, width: '98%', margin: '20px', '& .refundS': {
                        background: '#ffe599'
                    }
                }}>
                    <Typography variant='h5' component='h5' sx={{ textAlign: 'left', mb: 3, fontWeight: 'bold', fontSize: 18 }}>
                        {/* { title } */}Cvu/Transactions
                    </Typography>
                    {/* <Searchbar getTrx={getCvuTransaction} setPage={setPageState}/> */}

                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <DemoContainer components={['DatePicker']}> */}
                                <DatePicker disableFuture label="From" value={dateFilter.dateFrom} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateFrom: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                                {/* </DemoContainer> */}
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <DemoContainer components={['DatePicker']}> */}
                                <DatePicker disableFuture label="To" value={dateFilter.dateTo} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateTo: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                                {/* </DemoContainer> */}
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Status" select value={dateFilter.status} onChange={(newValue) => { setDateFilter({ ...dateFilter, status: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="Canceled">CANCELED</MenuItem>
                                <MenuItem value="Completed">COMPLETED</MenuItem>
                                <MenuItem value="Pending">PENDING</MenuItem>
                                <MenuItem value="Rejected">REJECTED</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Concept" select value={dateFilter.concept} onChange={(newValue) => { setDateFilter({ ...dateFilter, concept: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="VAR">VAR</MenuItem>
                                <MenuItem value="USE">USE</MenuItem>
                                <MenuItem value="TAX">TAX</MenuItem>
                                <MenuItem value="SIS">SIS</MenuItem>
                                <MenuItem value="SEG">SEG</MenuItem>
                                <MenuItem value="ROP">ROP</MenuItem>
                                <MenuItem value="">--</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Type" select value={dateFilter.type} onChange={(newValue) => { setDateFilter({ ...dateFilter, type: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="NC">NC</MenuItem>
                                <MenuItem value="ND">ND</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container mb={2}>
                        <Grid item xs={5} md={11} xl={10}>
                            <div className='input-wrapper'>
                                <SearchIcon id="search-icon"></SearchIcon>
                                <input placeholder='Search...' value={searched} onChange={(val) => setSearched(val.target.value)} />
                            </div>
                        </Grid>
                        <Grid item xs={7} md={1} xl={2}>
                            <Grid container justifyContent="flex-end">
                                <ExportButton data={dataExcel}></ExportButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <DataGrid
                        rowHeight={50}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        // pageSizeOptions={[5, 10, 25]}
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        onCellClick={(params) => {
                            if ((params.field === 'CVU')||(params.field === 'Alias')|| (params.field === 'Type')|| (params.field === 'Amount')|| (params.field === 'OrderId')|| (params.field === 'Description') )   {
                                setSearched(params.value);
                            }
                        }}
                        paginationMode='server'
                        onPageChange={(newPage) => {
                         
                            setPageState(old => ({ ...old, page: newPage + 1 }))
                        }}
                        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                        columns={columns}
                        getRowId={(row) => row.id}
                        onRowClick={(params, event) => {
                            setIdentificator(params.row.identificator)
                            setOpen(true);

                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'refund' && params.value !== null) {
                                return 'refundS'
                            }
                        }}
                        // filterModel={
                        //     {
                        //         items: [{
                        //             columnField: 'result', operatorValue: 'contains', value: 'close'
                        //         }]
                        //     }
                        // }
                        disableColumnMenu
                        sx={{ mt: 2 }}
                    >

                    </DataGrid>
                </Box>
            </section>

            {open ? <Modal openModal={open} idenTrx={identificator} onCloseModal={handleClose} /> : null}
        </div>
    )
}

export default CvuTransaction