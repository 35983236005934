import React, { useRef, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios'

import { PiCreditCard, PiMoneyDuotone, PiWalletLight, PiBagDuotone ,PiArchiveDuotone,PiBarcodeLight } from "react-icons/pi";

import "./sidebar.scss"

const Sidebar = () => {
  const refSidebar = useRef(null)
  const refLockIcon = useRef(null)
  const refClose = useRef(null)
  const refSubMenu0Items = useRef(null)
  const refSubMenu0Icon = useRef(null)
  const refSubMenu1Items = useRef(null)
  const refSubMenu1Icon = useRef(null)
  const refSubMenu2Items = useRef(null)
  const refSubMenu2Icon = useRef(null)
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/transactions') {
      toggleSubMenu0(true);
    }
    if (location.pathname === '/services') {
      toggleSubMenu1(true);
    }
    if (location.pathname === '/cvus') {
      toggleSubMenu2(true);
    }
  }, [location.pathname]);

  const toggleSubMenu0 = (force=false) => {
    if (refSubMenu0Icon.current && refSubMenu0Items.current) {
      const currentClassName = refSubMenu0Icon.current.className;
      const currentClassNameItems = refSubMenu0Items.current.className;
      if (currentClassNameItems.includes('hidden') || force) {
        refSubMenu0Icon.current.className = currentClassName.replace('bx-chevron-right', 'bx-chevron-down');
        refSubMenu0Items.current.classList.remove('hidden');
      } else {
        refSubMenu0Icon.current.className = currentClassName.replace('bx-chevron-down', 'bx-chevron-right');
        refSubMenu0Items.current.classList.add('hidden');
      }
    }
  };

  const toggleSubMenu1 = (force=false) => {
    if (refSubMenu1Icon.current && refSubMenu1Items.current) {
      const currentClassName = refSubMenu1Icon.current.className;
      const currentClassNameItems = refSubMenu1Items.current.className;
      if (currentClassNameItems.includes('hidden') || force) {
        refSubMenu1Icon.current.className = currentClassName.replace('bx-chevron-right', 'bx-chevron-down');
        refSubMenu1Items.current.classList.remove('hidden');
      } else {
        refSubMenu1Icon.current.className = currentClassName.replace('bx-chevron-down', 'bx-chevron-right');
        refSubMenu1Items.current.classList.add('hidden');
      }
    }
  };

  const toggleSubMenu2 = (force=false) => {
    if (refSubMenu2Icon.current && refSubMenu2Items.current) {
      const currentClassName = refSubMenu2Icon.current.className;
      const currentClassNameItems = refSubMenu2Items.current.className;
      if (currentClassNameItems.includes('hidden') || force) {
        refSubMenu2Icon.current.className = currentClassName.replace('bx-chevron-right', 'bx-chevron-down');
        refSubMenu2Items.current.classList.remove('hidden');
      } else {
        refSubMenu2Icon.current.className = currentClassName.replace('bx-chevron-down', 'bx-chevron-right');
        refSubMenu2Items.current.classList.add('hidden');
      }
    }
  };

  const toggleLock = () => {
    refLockIcon.current.addEventListener('click', lockRemove());
  }

  const lockRemove = () => {
    refSidebar.current.classList.toggle("locked")

    if(!refSidebar.current.classList.contains('locked')){
      refSidebar.current.classList.add('hoverable');
      refLockIcon.current.classList.replace('bx-lock-alt', 'bx-lock-open-alt');
    }else{
      refSidebar.current.classList.remove('hoverable');
      refLockIcon.current.classList.replace('bx-lock-open-alt', 'bx-lock-alt');
    }
  }

  const hideSidebar = () => {
    if(refSidebar.current.classList.contains('hoverable')){
      refSidebar.current.classList.add('close');
    }
  }

  const showSidebar = () => {
    if(refSidebar.current.classList.contains('hoverable')){
      refSidebar.current.classList.remove('close');
    }
  }

  const logout = () => {
    axios.post(process.env.REACT_APP_URL_API + 'logout',{}, {
        withCredentials: true
    }).then(function (response) {
        // console.log(response);
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  return (
    <nav ref={refSidebar} className="sidebar locked" onMouseLeave={() => hideSidebar()} onMouseEnter={() => showSidebar()}>
        <div className="logo_items flex">
          <span className="nav_image"></span>
          <span className="logo_name">IQ</span>

          <i ref={refLockIcon} className='bx bx-lock-alt' id="lock-icon" title="Unlock sidebar" onClick={() => toggleLock()}></i>
          <i ref={refClose} className='bx bx-x' id="sidebar-close"></i>
        </div>

        <div className="menu_container">
          <div className="menu_items">
            <ul className="menu_item">
              <div className="menu_title flex">
                <span className="title">Dashboard</span>
                <span className="line"></span>
              </div>
              <li className="item">
                <p className="link flex" onClick={() => toggleSubMenu0()}>
                  <i><PiMoneyDuotone/></i>
                  <span>Acquiring</span>
                  <i ref={refSubMenu0Icon} className='ml-auto bx bx-chevron-right' ></i>
                </p>
                <ul className="sub_menu_item hidden" ref={refSubMenu0Items} >
                  <Link to="/acquiring/transactions" style={{ textDecoration: "none" }}>
                    <li className="item">
                      <p className="link flex">
                        <i><PiCreditCard/></i>
                        <span>Cards</span>
                      </p>
                    </li>
                  </Link>
                  <Link to="/acquiring/request" style={{ textDecoration: "none" }}>
                    <li className="item">
                      <p className="link flex">
                        <i className='bx bx-file-find'></i>
                        <span>Request</span>
                      </p>
                    </li>
                  </Link>
                </ul>
              </li>
              <li className="item">
                <p className="link flex" onClick={() => toggleSubMenu2()}>
                  <i><PiBagDuotone/></i>
                  <span>CVUs</span>
                  <i ref={refSubMenu2Icon} className='ml-auto bx bx-chevron-right' ></i>
                </p>
                <ul className="sub_menu_item hidden" ref={refSubMenu2Items} >
                  <Link to="/cvus/cvus" style={{ textDecoration: "none" }}>
                    <li className="item">
                      <p className="link flex">
                        <i><PiWalletLight/></i>
                        <span>CVUs</span>
                      </p>
                    </li>
                  </Link>
                  <Link to="/cvus/transactions" style={{ textDecoration: "none" }}>
                    <li className="item">
                      <p className="link flex">
                        <i><PiCreditCard/></i>
                        <span>Transactions</span>
                      </p>
                    </li>
                  </Link>
                  <Link to="/cvus/request" style={{ textDecoration: "none" }}>
                    <li className="item">
                      <p className="link flex">
                        <i className='bx bx-file-find'></i>
                        <span>Requests</span>
                      </p>
                    </li>
                  </Link>
                </ul>
              </li>
              <li className="item">
                <p className="link flex" onClick={() => toggleSubMenu1()}>
                  <i><PiWalletLight/></i>
                  <span>Services</span>
                  <i ref={refSubMenu1Icon} className='ml-auto bx bx-chevron-right' ></i>
                </p>
                <ul className="sub_menu_item hidden" ref={refSubMenu1Items} >
                  <Link to="/services/services" style={{ textDecoration: "none" }}>
                    <li className="item">
                      <p className="link flex">
                        <i className='bx bx-service'><PiArchiveDuotone /></i>
                        <span>Services</span>
                      </p>
                    </li>
                  </Link>
                  <Link to="/services/payments" style={{ textDecoration: "none" }}>
                    <li className="item">
                      <p className="link flex">
                        <i className='bx bx-payment'><PiBarcodeLight /></i>
                        <span>Payments</span>
                      </p>
                    </li>
                  </Link>
                </ul>
              </li>


            </ul>
          </div>

          <div className="bottom-content">
            <Link to="/login" style={{ textDecoration: "none" }} onClick={logout}>
              <li className="item">
                <p className="link flex">
                  <i className='bx bx-log-out icon'></i>
                  <span>Logout</span>
                </p>
              </li>
            </Link>
          </div>
        </div>
    </nav>
  )
}

export default Sidebar
