import React from 'react'
import Cancel from '../../assets/boton-eliminar.png'
import './notify.scss'
import Sidebar from '../../Components/Sidebar/Sidebar'
import { Grid } from '@mui/material'

const Canceled = () => {
    return (
        <>
        <Sidebar/>

        <section className="containerTrx">

            <Grid container justifyContent="center" alignItems="stretch" mt={5}>
                <Grid item xs={4}>
                    <div className='card1'>
                        <div className='upper-container-c'>
                            <div className='image-container'>
                            <img src={Cancel} alt="100px" width="100px"/>
                            </div>
                        </div>

                        <div className='low-container'>
                            <h3>Payment Canceled</h3>
                        </div>
                    </div>
                </Grid>

            </Grid>

        </section>
    </>
    )
}

export default Canceled