import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import Modal from '../../Components/Services/Modal/Modal.jsx';

import Sidebar from '../../Components/Sidebar/Sidebar.jsx'

import "./services.scss"
import ExportButton from '../../Components/Services/ExportButton/ExportButton.jsx'

const Services = () => {
    const [open, setOpen] = useState(false);
    const [identificator, setIdentificator] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [searched, setSearched] = useState(null)
    const [dataExcel, setDataExcel] = useState([])
    const [dateFilter, setDateFilter] = useState({
        dateFrom: null,
        dateTo: null,
        selected: -1,

        active: "ALL",
        category: "ALL",
        processor: "ALL",
        subprocessor: "ALL"
    });

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    useEffect(() => {
        getUser()
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.processor === "ALL" && dateFilter.active === "ALL" && dateFilter.category === "ALL" && dateFilter.subprocessor === "ALL") {
                getServices()
                getServicesExcel()
            } else {
                searchServices()
                searchServicesExcel()
            }
        }, 500)

        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.page, pageState.pageSize])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searched === null && dateFilter.dateFrom === null && dateFilter.dateTo === null && dateFilter.processor === "ALL" && dateFilter.active === "ALL" && dateFilter.subprocessor === "ALL") {
                setPageState(old => ({ ...old, page: 1 }))
                getServices()
                getServicesExcel()
            } else {
                searchServices()
                searchServicesExcel()
            }
        }, 500)

        return () => clearTimeout(timer)
    }, [searched, dateFilter.dateFrom, dateFilter.dateTo, dateFilter.processor, dateFilter.active, dateFilter.category, dateFilter.subprocessor])

    const columns = [
        {
            field: 'Description', width: 250, headerName: "Description"
        },
        {
            field: 'Category', width: 190, headerName: "Category"
        },
        {
            field: 'Active', width: 100, headerName: "Active"
        },
        {
            field: 'Processor', width: 140, headerName: "Processor"
        },
        {
            field: 'SubProcessor', width: 140, headerName: "SubProcessor"
        },
        {
            field: 'IdAtProcessor', width: 150, headerName: "IdAtProcessor"
        },
        {
            field: 'WithInvoice', width: 140, headerName: "WithInvoice"
        },

        {
            field: 'Id', width: 250, headerName: "Id"
        }
    ]

    const getUser = async () => {
        await axios.get(process.env.REACT_APP_URL_API + 'user', {
            withCredentials: true
        }).then((response) => {
            // const data = response.data;
            // console.log(response.data)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setRedirect(true)
                }
            });
    }

    if (redirect) {
        return <Navigate to="/login" />
    }

    const getServices = async () => {

        setPageState(old => ({ ...old, isLoading: true }))

        await axios.get(process.env.REACT_APP_URL_API + `front/services?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        })
            .then(function (response) {

                setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))

            })
            .catch(function (error) {

                if (error.response.status === 401) {
                    toast.error('Credenciales Invalidas', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const searchServices = async () => {
        setPageState(old => ({ ...old, isLoading: true }))

        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.processor + "," + dateFilter.active+ "," + dateFilter.category+ "," +  dateFilter.subprocessor

        await axios.get(process.env.REACT_APP_URL_API + `front/services/search/${searchedVal}?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        }).then((response) => {
            // console.log(response);
            setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const getServicesExcel = async () => {
        await axios.get(process.env.REACT_APP_URL_API + `front/servicesExcel`, {
            withCredentials: true
        })
        .then(function (response) {
            let listTrx = response.data.map((d) => {
                return {
                   
                    Description: d.Description,
                    Category: d.Category,
                    Active: d.Active,
                    Processor: d.Processor,
                    SubProcessor: d.SubProcessor,
                    IdAtProcessor: d.IdAtProcessor,
                    WithInvoice: d.WithInvoice,
                    Id: d.Id
                }

            })

            setDataExcel(listTrx)
        })
        .catch(function (error) {

            if (error.response.status === 401) {
                toast.error('Credenciales Invalidas', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    }

    const searchServicesExcel = async () => {
        let dFrom = dateFilter.dateFrom === null ? null : dayjs(dateFilter.dateFrom).format('YYYY-MM-DD')
        let dTo = dateFilter.dateTo === null ? null : dayjs(dateFilter.dateTo).format('YYYY-MM-DD')

        let searchedVal = searched + "," + dFrom + "," + dTo + "," + dateFilter.processor + "," + dateFilter.active + "," + dateFilter.category + "," + dateFilter.subprocessor

        await axios.get(process.env.REACT_APP_URL_API + `front/servicesExcel/search/${searchedVal}`, {
            withCredentials: true
        }).then((response) => {
            let listTrx = response.data.map((d) => {
                return {
                    Description: d.Description,
                    Category: d.Category,
                    Active: d.Active,
                    Processor: d.Processor,
                    SubProcessor: d.SubProcessor,
                    IdAtProcessor: d.IdAtProcessor,
                    WithInvoice: d.WithInvoice,
                    Id: d.Id
                }
            })

            setDataExcel(listTrx)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Sidebar />
            <ToastContainer />
            <section className="containerTrx">
                <Box sx={{
                    height: 2650, width: '98%', margin: '20px', '& .refundS': {
                        background: '#ffe599'
                    }
                }}>
                    <Typography variant='h5' component='h5' sx={{ textAlign: 'left', mb: 3, fontWeight: 'bold', fontSize: 18 }}>
                        {/* { title } */}Services
                    </Typography>
                    {/* <Searchbar getTrx={getServices} setPage={setPageState}/> */}

                    <Grid container justifyContent="space-between">

{/*}

                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                               
                                <DatePicker disableFuture label="From" value={dateFilter.dateFrom} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateFrom: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                             
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5} xl={1.5} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              
                                <DatePicker disableFuture label="To" value={dateFilter.dateTo} onChange={(newValue) => { setDateFilter({ ...dateFilter, dateTo: newValue }) }} format='DD/MM/YYYY' slotProps={{ textField: { size: "small" } }} sx={{ width: "100%" }} />
                           
                            </LocalizationProvider>
                        </Grid>

*/}

                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField 
                                label="Category" 
                                select 
                                value={dateFilter.category} 
                                onChange={(newValue) => { setDateFilter({ ...dateFilter, category: newValue.target.value }) }} 
                                fullWidth 
                                color='info' 
                                size='small'
                            >
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="ADM PROPIEDADES">ADM PROPIEDADES</MenuItem>
                                <MenuItem value="ADMINISTRACIONES">ADMINISTRACIONES</MenuItem>
                                <MenuItem value="AGUA">AGUA</MenuItem>
                                <MenuItem value="AGUA Y GAS">AGUA Y GAS</MenuItem>
                                <MenuItem value="CLUBES">CLUBES</MenuItem>
                                <MenuItem value="CONSUMO">CONSUMO</MenuItem>
                                <MenuItem value="COOPERATIVA">COOPERATIVA</MenuItem>
                                <MenuItem value="COSMÉTICA">COSMÉTICA</MenuItem>
                                <MenuItem value="DONACIONES">DONACIONES</MenuItem>
                                <MenuItem value="ELECTRICIDAD">ELECTRICIDAD</MenuItem>
                                <MenuItem value="ENERGIA">ENERGIA</MenuItem>
                                <MenuItem value="ENT FINANCIERAS">ENT FINANCIERAS</MenuItem>
                                <MenuItem value="ESPECIAL">ESPECIAL</MenuItem>
                                <MenuItem value="GENERICO">GENERICO</MenuItem>
                                <MenuItem value="IMPUESTOS">IMPUESTOS</MenuItem>
                                <MenuItem value="INST EDUCACIONALES">INST EDUCACIONALES</MenuItem>
                                <MenuItem value="INST EDUCACIONALOES">INST EDUCACIONALOES</MenuItem>
                                <MenuItem value="INTERNET">INTERNET</MenuItem>
                                <MenuItem value="MEDICINA PREPAGA">MEDICINA PREPAGA</MenuItem>
                                <MenuItem value="MUNICIPALIDADES">MUNICIPALIDADES</MenuItem>
                                <MenuItem value="SEGURIDAD">SEGURIDAD</MenuItem>
                                <MenuItem value="SEGUROS">SEGUROS</MenuItem>
                                <MenuItem value="TARJETAS DE CREDITO">TARJETAS DE CREDITO</MenuItem>
                                <MenuItem value="TELEFONÍA">TELEFONÍA</MenuItem>
                                <MenuItem value="TV CABLE">TV CABLE</MenuItem>
                                <MenuItem value="VARIOS">VARIOS</MenuItem>
                                <MenuItem value="VIAJES Y TURISMO">VIAJES Y TURISMO</MenuItem>
                                <MenuItem value="VUELTO">VUELTO</MenuItem>
                                <MenuItem value="">--</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Active" select value={dateFilter.active} onChange={(newValue) => { setDateFilter({ ...dateFilter, active: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="0">0</MenuItem>
                                <MenuItem value="">--</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Processor" select value={dateFilter.processor} onChange={(newValue) => { setDateFilter({ ...dateFilter, processor: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="Ripsa">RIPSA</MenuItem>
                            </TextField>
                        </Grid>


                        <Grid item xs={12} sm={5} xl={2} mb={2}>
                            <TextField label="Subprocessor" select value={dateFilter.subprocessor} onChange={(newValue) => { setDateFilter({ ...dateFilter, subprocessor: newValue.target.value }) }} fullWidth color='info' size='small'>
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="RIPSA">RIPSA</MenuItem>
                                <MenuItem value="PROVINCIA NET">PROVINCIA NET</MenuItem>
                                <MenuItem value="">--</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container mb={2}>
                        <Grid item xs={5} md={11} xl={10}>
                            <div className='input-wrapper'>
                                <SearchIcon id="search-icon"></SearchIcon>
                                <input placeholder='Search...' value={searched} onChange={(val) => setSearched(val.target.value)} />
                            </div>
                        </Grid>
                        <Grid item xs={7} md={1} xl={2}>
                            <Grid container justifyContent="flex-end">
                                <ExportButton data={dataExcel}></ExportButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <DataGrid
                        rowHeight={50}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        // pageSizeOptions={[5, 10, 25]}
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        paginationMode='server'
                        onPageChange={(newPage) => {
                         
                            setPageState(old => ({ ...old, page: newPage + 1 }))
                        }}
                        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                        columns={columns}
                        getRowId={(row) => row.Id}
                        onRowClick={(params, event) => {
                            setIdentificator(params.row.identificator)
                            setOpen(true);

                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'refund' && params.value !== null) {
                                return 'refundS'
                            }
                        }}
                        // filterModel={
                        //     {
                        //         items: [{
                        //             columnField: 'result', operatorValue: 'contains', value: 'close'
                        //         }]
                        //     }
                        // }
                        disableColumnMenu
                        sx={{ mt: 2 }}
                    >

                    </DataGrid>
                </Box>
            </section>

            {open ? <Modal openModal={open} idenTrx={identificator} onCloseModal={handleClose} /> : null}
        </div>
    )
}

export default Services