import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import Transaction from './Pages/Transaction/Transaction';
import Request from './Pages/Request/Request'
import Card from './Pages/Card/Card';
import Active from './Pages/Card/Active';
import CvuRequest from './Pages/CvuRequest/CvuRequest';
import CvuTransaction from './Pages/CvuTransaction/CvuTransaction'

import Success from './Pages/Notify/Success';
import Canceled from './Pages/Notify/Canceled';
import Cvu from './Pages/Cvu/Cvu';
import Services from './Pages/Services/Services';
import ServicesPayments from './Pages/ServicesPayments/ServicesPayments'    

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path='/login'>
              <Route index element={<Login/>} />
            </Route>
            <Route path="/">
              <Route index element={<Home/>} />
            </Route>
            <Route path="acquiring/transactions" element={<Transaction/>} />
            <Route path="acquiring/request" element={<Request/>} />
            <Route path="cvus/cvus" element={<Cvu/>} />
            <Route path="cvus/request" element={<CvuRequest/>} />   
            <Route path="cvus/transactions" element={<CvuTransaction/>} />   
            <Route path="pomelo" element={<Card/>} />
            <Route path="pomelo/activeCard/:card" element={<Active/>} />
            <Route path="/payment/success" element={<Success/>} />
            <Route path="/payment/canceled" element={<Canceled/>} />
            <Route path="/services/services" element={<Services/>} />
           <Route path="/services/payments" element={<ServicesPayments/>} />    
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
