import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useParams } from 'react-router-dom';

const Active = () => {

    const params = useParams()

    useEffect(() => {
        activeCard()
    }, [])

    const activeCard = () => {
        // console.log(params.card)

        const payload = {
            "cid" : params.card
        };

        const headers = {
            'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ik1hcnRhIHRlc3QxIiwiaWF0IjoxNTE2MjM5MDIyfQ.19E2hQPdgjQDOmX4_v9baKmu4DzkrTl-d4JCM8JeIR0",
            'clientId': "101"
        }
    
        axios.post(process.env.REACT_APP_URL_POMELOAPI + 'cardActivation', payload, {
            headers
        })
        .then(function (response) {
            successMessage('Card activated.')
        })
        .catch(function (error) {
            console.log(error);

            errorMessage('Error')
        });
    }

    const errorMessage = (message) => {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const successMessage = (message) => {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    return (
        <div> <ToastContainer/> TEST ACTIVACIÓN DE TARJETA</div>
    )
}

export default Active