import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {Dialog, DialogContent, Grid, AppBar, Toolbar, IconButton, Typography, Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid'
import { CopyAll } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

const Modal = (props) => {
    const [dataModal, serDataModal] = useState();
    const [logModal, setLogModal] = useState();

    useEffect(() => {
        loadDataModal();
        loadGridModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.openModal])

    useEffect(() => {
        renderInfoModal();
        renderLogsModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataModal, logModal])

    const columnsModal = [
        {
            field: 'processor', width:120, headerName: 'Processor',
        },
        {
            field: 'type', width:350, headerName: "Type"
        },
        {
            field: 'request', width:500, headerName: "Request"
        },
        {
            field: 'response', width:500, headerName: "Response"
        },
        {
            headerName: "Actions", renderCell: (row) => {
                return (
                    <div>
                        <IconButton placeholder='Request' color='info' onClick={() => {
                            copyData(row, 'request')
                        }}>
                            <CopyAll placeholder='Request'/>
                        </IconButton>

                        <IconButton aria-label='Response' color='info' onClick={() => {
                            copyData(row, 'response')
                        }}>
                            <CopyAll aria-label='Response'/>
                        </IconButton>
                    </div>
                )
            }
        }
    ]

    const loadDataModal =  async () => {
        await axios.get(process.env.REACT_APP_URL_API + `front/transactions/${props.idenTrx}`, {
            withCredentials: true
        })
        .then(function (response) {
            serDataModal(response.data)
        })
        .catch(function (error) {
        console.log(error);

        // if(error.response.status === 401){
        //     toast.error('Credenciales Invalidas', {
        //     position: "bottom-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //     });
        // }
        });
    }
    
    const loadGridModal =  async () => {
        await axios.get(process.env.REACT_APP_URL_API + `front/logs/${props.idenTrx}`, {
            withCredentials: true
        })
        .then(function (response) {
            // console.log(response.data)
            setLogModal(response.data)
        })
        .catch(function (error) {
        console.log(error);

        // if(error.response.status === 401){
        //     toast.error('Credenciales Invalidas', {
        //     position: "bottom-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //     });
        // }
        });
    }

    const renderInfoModal = () => dataModal
    const renderLogsModal = () => logModal

    const copyData = (data, colunm) => {
        if(colunm === 'request'){
            navigator.clipboard.writeText(data.row.request);
        }else{
            navigator.clipboard.writeText(data.row.response);
        }

        toast.success('Copied '+colunm+"!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    if(renderInfoModal() && renderLogsModal()){
        return (
            <Dialog
                fullScreen
                open={props.openModal}
                onClose={props.onCloseModal}
                // TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 1, flex: 2 }} variant="h6" component="div">Identifier  {props.idenTrx}</Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.onCloseModal}
                            aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Sound
                        </Typography>
                        <Button autoFocus color="inherit" onClick={props.onCloseModal}>
                        save
                        </Button> */}
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <ToastContainer/>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="document_type">ID: {renderInfoModal().id}</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="document_type">Processor: {renderInfoModal().namePR}</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="document_type">Operation ID: {renderInfoModal().gw_trxId}</label>
                        </Grid>
                        <Grid item xs={12} sm={6} mb={6}>
                            <label htmlFor="document_type">Data/Time: {new Date(renderInfoModal().updated_at).toLocaleString('en-US')}</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="document_type">Status: {renderInfoModal().status}</label>
                            {/* <h4>{account.den_calle}</h4> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Amount: {renderInfoModal().amount}</label>
                        {/* <h4>{account.plod}</h4> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Message: {renderInfoModal().result}</label>
                        {/* <h4>{account.den_localidad}</h4> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Currency: {renderInfoModal().currency}</label>
                        {/* <h4>{account.den_barrio}</h4> */}
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Info: {renderInfoModal().updated_at}</label>
                        {/* <h4>{account.den_telefono}</h4> 
                        </Grid> */}
                        <Grid item xs={12} sm={6} mb={6}>
                        <label htmlFor="document_type">Auth: {renderInfoModal().auth}</label>
                        {/* <h4>{account.tipo_doc}</h4> */}
                        </Grid>
    
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Brand: {renderInfoModal().brand}</label>
                        {/* <h4>{account.nro_cedula}</h4> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Establishment: {renderInfoModal().name}</label>
                        {/* <h4>{account.cod_provincia}</h4> */}
                        </Grid>
    
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Product: {renderInfoModal().product}</label>
                        {/* <h4>{account.den_provincia}</h4> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Ext. ID: {renderInfoModal().external_transaction_id}</label>
                        {/* <h4>{account.den_postal}</h4> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <label htmlFor="document_type">Bin: {renderInfoModal().bin}</label>
                        {/* <h4>{account.imp_lim}</h4> */}
                        </Grid>
                        <Grid item xs={12} sm={6} mb={5}>
                        <label htmlFor="document_type">Last Four: {renderInfoModal().card}</label>
                        {/* <h4>{account.date_ipdate}</h4> */}
                        </Grid>
                    </Grid>

                    {/* <section className="containerTrx"> */}
                        <Box sx={{ height: 300, width:'98%'}}>
                            <DataGrid
                                rowHeight={50}
                                rows={renderLogsModal()}
                                // rowCount={pageState.total}
                                // loading={pageState.isLoading}
                                columns={columnsModal}
                                hideFooterPagination={true}
                                hideFooter={true}
                                disableColumnMenu>
                            </DataGrid>
                        </Box>
                    {/* </section> */}
                </DialogContent>
            </Dialog>
        )

    }

        


    
}

export default Modal