import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import MailIcon from '@mui/icons-material/Mail';
import PasswordIcon from '@mui/icons-material/Password';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, IconButton, Slide, Typography, DialogContent, DialogContentText, Grid } from '@mui/material';

import { TbFaceId } from "react-icons/tb";
import { IoFingerPrintSharp } from "react-icons/io5";

import { Authsignal } from "@authsignal/browser";

import './login.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  // const [t, i18n] = useTranslation("test")
  const [t] = useTranslation("test")

  useEffect(() => {
    document.body.classList.add(
      'bodyLogin'
    )
  })

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailPasskey, setEmailPasskey] = useState('');
  const [userId, setUseriD] = useState(0);

  useEffect(() => {
    if(userId !== 0){
      loginIQPasskey()
    }
  }, [userId])

  // useEffect(() => {
  //   singInPasskey()
  // }, [])

  const submitHandler = (e) => {
    e.preventDefault()

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    axios.get(process.env.REACT_APP_URL_CSRF + 'sanctum/csrf-cookie').then(() => {
      const payload = {
        email,
        password
      };

      axios.post(process.env.REACT_APP_URL_API + 'login', payload, {
        withCredentials: true
      })
      .then(function (response) {
        setRedirect(true)
      })
      .catch(function (error) {
        console.log(error);
  
        if(error.response.status === 401){
          errorMessage('Credenciales Invalidas')
        }
      });
    });
  }

  const loginIQPasskey = () => {
    axios.get(process.env.REACT_APP_URL_CSRF + 'sanctum/csrf-cookie').then(() => {

      const payload = {
        userId
      };

      axios.post(process.env.REACT_APP_URL_API + 'login', payload, {
        withCredentials: true
      })
      .then(function (response) {
        setRedirect(true)
      })
      .catch(function (error) {
        console.log(error);
  
        if(error.response.status === 401){
          errorMessage('Credenciales Invalidas')
        }
      });
    });
  }

  if(redirect){
    return <Navigate to="/"/>
  }

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const singUpPasskey = () => {
    let authsignalService = new Authsignal({tenantId: process.env.REACT_APP_TENANDAID, baseUrl: process.env.REACT_APP_AUTSHIGNAL_URL})

    axios.post(process.env.REACT_APP_URL_API + 'authsignal/trackAction', {mail: emailPasskey, action: 'signUp'})
    .then(function (response) {
      console.log(response.data)
      authsignalService.passkey.signUp({userName: emailPasskey, token: response.data.token})
      .then(function (res){
        console.log(res)

        decryptToken(res)
      }).catch(function (e){
          console.log(e)
      })
    })
    .catch(function (error) {
      console.log(error);

      errorMessage(error.response.data.message)
    });
  }

  const singInPasskey = () => {
    let authsignalService = new Authsignal({tenantId: process.env.REACT_APP_TENANDAID, baseUrl: process.env.REACT_APP_AUTSHIGNAL_URL})

    authsignalService.passkey.signIn()
    .then(function (res){
      console.log(res)

      decryptToken(res)
    }).catch(function (e){
        console.log(e)
    })
  }

  const decryptToken = (token) => {
    axios.post(process.env.REACT_APP_URL_API + 'authsignal/token', {token: token})
    .then(function (response) {
      console.log(response)
      setUseriD(response.data.userId)

      // loginIQPasskey()
    }).catch(function (e){
      console.log(e)

      errorMessage("Error decrypting data")
    })
  }

  const errorMessage = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  return (
    <>
      <form className="accountWrapper" onSubmit={submitHandler}>
        <ToastContainer/>
        <div className="container">
          <div className="desing">
            <div className="pill-1 rotate-45"></div>
            <div className="pill-2 rotate-45"></div>
            <div className="pill-3 rotate-45"></div>
            <div className="pill-4 rotate-45"></div>
          </div>
          <div className="login">
            <h3 className="title">{t("title")}</h3>
              <div className="text-input">
                <MailIcon className='icon'/>
                <input type="text" placeholder='email' name="email" id="email" onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className="text-input">
                <PasswordIcon className='icon'/>
                <input type="password" placeholder='password' name="password" id="password" onChange={(e) => setPassword(e.target.value)}/>
              </div>
              <button type="submit" className='login-button'>Sign In</button>
              <IconButton sx={{ mt: 3}} color= "info" size= "large" onClick={() => openModal()}>
                <TbFaceId fontSize="inherit"/><IoFingerPrintSharp/>
              </IconButton>
          </div>
        </div>
        {/* <button onClick={() => i18n.changeLanguage("es")}>Español</button>
        <button onClick={() => i18n.changeLanguage("en")}>Ingles</button> */}
      </form>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogContent>
            <Box sx={{ m: 3}} container>
              <DialogContentText id="alert-dialog-slide-description" className='dialog'>
                <Box sx={{ fontWeight:"bold", mb: 4}}>Enter your email address to get started creating your passkey.</Box>
                <Box>
                  <div className="text-input">
                    <MailIcon className='icon'/>
                    <input type="text" placeholder='email' name="email" id="email1" onChange={(e) => setEmailPasskey(e.target.value)}/>
                  </div>

                  <Box sx={{ mb: 3 }}>
                    <button className='login-button' onClick={() => singUpPasskey()}>Create</button>
                  </Box>

                  <Grid container>
                    <Grid item xs = {5} sx={{ mt: 1.3 }}><hr /></Grid>
                    <Grid item xs = {2}><Typography align='center' sx={{ fontWeight:"bold"}}>Or</Typography></Grid>
                    <Grid item xs = {5} sx={{ mt: 1.3 }}><hr /></Grid>
                  </Grid>

                  <Box sx={{ mt: 3, fontWeight:"bold" }}>
                    <button onClick={() => singInPasskey()} className='login-button'>Sign In with existing passkey</button>
                  </Box>
                </Box>
              </DialogContentText>
            </Box>
          </DialogContent>
      </Dialog>
    </>
  )
}

export default Login