import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';

import Sidebar from '../../Components/Sidebar/Sidebar'
import ExportButton from '../../Components/Cvu/ExportButton/ExportButton'

const Cvu = () => {
    const [redirect, setRedirect] = useState(false);
    const [dataExcel, setDataExcel] = useState([])
    const [searched, setSearched] = useState(null)
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {

        if (searched === null){

            getCvus()
            getCvusExcel()

        }else{

            searchCvus()
            searchCvusExcel()

        }


    }, [searched,pageState.page, pageState.pageSize])

    const columns = [
        {
            field: 'id', width:60, headerName: '#', align: 'right'
        },
        {
            field: 'cbu', width:210, headerName: "Cbu Company", valueGetter: paramas => paramas.row.cbu.cbu
        },
        {
            field: 'ownerName', width:200, headerName: "Owner Name"
        },
        {
            field: 'cvu', width:210, headerName: "Cvu"
        },
        {
            field: 'alias', width:200, headerName: "Alias"
        },
        {
            field: 'name', width:150, headerName: "Company", valueGetter: paramas => paramas.row.cbu.company.name
        },
        {
            field: 'created_at', width: 200, headerName: "Created", valueGetter: (paramas) => {
                return new Date(paramas.value).toLocaleString('en-GB');
            }
        }
    ]

    const getUser =  async () => {
        await axios.get(process.env.REACT_APP_URL_API + 'user', {
            withCredentials: true
        }).then((response) => {
            // const data = response.data;
            // console.log(response.data)
        })
        .catch(function (error) {
            if(error.response.status === 401){
                setRedirect(true)
            }
        });
    }



    const searchCvus = async () => {
        setPageState(old => ({ ...old, isLoading: true }))


        let searchedVal = searched + ","

        await axios.get(process.env.REACT_APP_URL_API + `front/cvu/search/${searchedVal}?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        }).then((response) => {
            // console.log(response);
            setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total }))
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }


    const searchCvusExcel = async () => {

        let searchedVal = searched  + ","

        await axios.get(process.env.REACT_APP_URL_API + `front/cvuExcel/search/${searchedVal}`, {
            withCredentials: true
        }).then((response) => {
            let listTrx = response.data.map((d) => {
                return {
                    ID: d.id,
                    Cbu_Company: d.cbu.cbu,
                    Owner_Name: d.ownerName,
                    Cvu: d.cvu,
                    Alias: d.alias,
                    Company: d.cbu.company.name,
                    Created: new Date(d.created_at).toLocaleString('en-GB')
                }
            })

            setDataExcel(listTrx)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error('Invalid credentials', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (error.response.status >= 400) {
                    toast.error('Failed to load resource', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }




    const getCvus =  async () => {
        setPageState(old => ({...old, isLoading: true}))

        await axios.get(process.env.REACT_APP_URL_API + `front/cvus?page=${pageState.page}&page_size=${pageState.pageSize}`, {
            withCredentials: true
        })
        .then(function (response) {
            setPageState(old => ({...old, isLoading: false, data:response.data.data, total:response.data.total}))
        })
        .catch(function (error) {
            console.log(error)

            if(error.response.status === 401){
                toast.error('Credenciales Invalidas', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            } else if(error.response.status >= 400){
                toast.error('Failed to load resource', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    }

    const getCvusExcel =  async () => {
        await axios.get(process.env.REACT_APP_URL_API + `front/cvuExcel`, {
            withCredentials: true
        })
        .then(function (response) {
            let listTrx = response.data.map((d) => {
                return {
                    ID: d.id,
                    Cbu_Company: d.cbu.cbu,
                    Owner_Name: d.ownerName,
                    Cvu: d.cvu,
                    Alias: d.alias,
                    Company: d.cbu.company.name,
                    Created: new Date(d.created_at).toLocaleString('en-GB')
                }
            })

            setDataExcel(listTrx)
        })
        .catch(function (error) {
            // console.log(error)
            if(error.response.status === 401){
                toast.error('Credenciales Invalidas', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            }
        });
    }

    if(redirect){
        return <Navigate to="/login"/>
    }

    return (
        <div>
            <Sidebar/>
            <ToastContainer/>
            <section className="containerTrx">
                <Box sx={{ height: 2650, width:'98%', margin: '20px', '& .refundS': {
                    background: '#ffe599'
                }}}>
                    <Typography variant='h5' component='h5' sx={{ textAlign:'left', mb:3, fontWeight: 'bold', fontSize: 18 }}> Cvus </Typography>

                    <Grid container mb={2}>
                         <Grid item xs={5} md={11} xl={10}>
                            <div className='input-wrapper'>
                                <SearchIcon id="search-icon"></SearchIcon>
                                <input placeholder='Search...'  value={searched} onChange={(val) => setSearched(val.target.value)} />
                            </div>
                        </Grid> 
                        <Grid item xs={7} md={12} xl={12}>
                            <Grid container justifyContent="flex-end">
                                <ExportButton data={dataExcel}></ExportButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <DataGrid
                        rowHeight={50}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        // pageSizeOptions={[5, 10, 25]}
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        paginationMode='server'
                        onPageChange={(newPage) =>
                            {
                                // console.log("asdsa");
                                setPageState(old => ({...old, page: newPage + 1}))
                            }}
                        onPageSizeChange={(newPageSize) => setPageState(old => ({...old, pageSize: newPageSize}))}
                        columns={columns}
                        disableColumnMenu
                        sx={{ mt: 2 }}
                    >

                    </DataGrid>
                </Box>
            </section>
        </div>
    )
}

export default Cvu