import React from 'react'
import { IconButton } from '@mui/material'
import { RiFileExcel2Fill } from "react-icons/ri";
import * as XLSX from "xlsx"

const ExportButton = ({data}) => {

    const handleDownload = () => {
        const libro = XLSX.utils.book_new()

        const hoja = XLSX.utils.json_to_sheet(data)

        XLSX.utils.book_append_sheet(libro, hoja, "Cvus")

        XLSX.writeFile(libro, "Cvus.xlsx")
    }

    return (
        <>
            <IconButton aria-label='Export Excel' color='success' size='large' onClick={handleDownload}>
                <RiFileExcel2Fill></RiFileExcel2Fill>
            </IconButton>
        </>
    )
}

export default ExportButton